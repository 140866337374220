.card {
    padding: 0 0% 0 0%;
}

.tbl {
    border-collapse: collapse;
}

.tbl thead th {
    text-align: center;
    background: #404e66;
    color: white;
    padding: 4px;
    font-size: 14px;
    text-transform: uppercase
}

.media-body>h5 {
    font-size: 15px;
}