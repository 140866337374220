.guest-login .MuiCardContent-root{
    padding: 78px 60px 30px !important;
}
.guest-login p {
    margin-bottom: 0 !important;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
}
.guest-login .submit-btn {
    min-width: 200px;
}
.guest-login .sub-btn{
    margin-bottom: 0 !important;
    margin-top: 20px;
    padding-bottom: 65px;
}
.form-simple .com-field label::after{
    content: '*';
    color: red;
    margin-left: 5px;
    font-weight: 600;
}