.our-manufacturer{
    padding: 50px 0 30px !important    
}


  .certified .certified-col
  {
      text-align: center;
  }
  @media (max-width: 767px){
    .our-manufacturer{
        padding: 15px 0 15px !important  
    }
    .certified .certified-col
    {
        text-align: left;
        margin: 5px 0px;
    }
    .support-border-right {
        border-right: none !important;
    }
  }