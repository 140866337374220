.App {
	text-align: center;
  }
  
  .App-logo {
	animation: App-logo-spin infinite 20s linear;
	height: 40vmin;
	pointer-events: none;
  }
  
  .App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
  }
  
  .App-link {
	color: #61dafb;
  }
  
  @keyframes App-logo-spin {
	from {
	  transform: rotate(0deg);
	}
	to {
	  transform: rotate(360deg);
	}
  }
  
  /*Body*/
  
  body, html{
	  font-family: 'Roboto',"Helvetica Neue",Helvetica,Arial,sans-serif !important;
	  font-size: 14px !important;
	  font-weight: normal !important;
	  background: #efefef !important;
  }
  
  /*Register, login, signup Form CSS*/
  input[type='text'] {
	  border: none !important;
  }
  input:focus{
	  box-shadow: none !important;
	  border:none !important;
  }
  
  .submit-btn{
	  background: #01524b !important;
	  color: #fff !important;
	  border-radius: 50px !important;
	  padding: 5px 25px !important;
	  float: right !important;
  }
  .guest-login{
	  background: #fff;
  }
  .user-login{
	  background-image: url('images/login-bg.jpg');
	  background-position: bottom;
	  background-size: cover;
  }
  
  .heading{
	  font-family: 'Roboto',"Helvetica Neue",Helvetica,Arial,sans-serif;
	  text-transform: capitalize;
	  /* font-size: 20px !important; */
    text-transform: capitalize !important;
  }
  .subheading{
	  font-family: 'Roboto',"Helvetica Neue",Helvetica,Arial,sans-serif;
	  /* font-size: 20px; */
    /* font-weight: 600; */
    /* text-transform: capitalize !important; */
    text-transform: initial !important;
  }
  .MuiInputLabel-animated {
    font-weight: 400 !important;
    text-transform: uppercase;
    color: #000 !important;
    font-size: 12px !important;
  }
  .MuiInputBase-root {
    font-size: 14px !important ;
  }

  .MuiInputBase-input:focus, .MuiInputBase-input:active, .MuiInputBase-input:visited, .MuiInputBase-input:focus-within {
    background-color: transparent !important;
  }
  .MuiInputBase-input:-webkit-autofill,
  .MuiInputBase-input:-webkit-autofill:hover,
  .MuiInputBase-input:-webkit-autofill:focus,
  .MuiInputBase-input:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s !important;
  }
  @media (max-width:576px){
.register{
   width: 102px;
    line-height: 22px;
    display: block;
    height: 25px;
}
  }
  .register{
	  background : #01524b;
	  color: #fff;
	  padding:3px 5px;
	  border-radius:5px;
	  font-size:14px;
  }
  .register:hover{
	  color: #fff;
  }
  
  /*manufacturing css*/
  .certified{
	  padding: 15px;
	  background: #fff;
	  border-radius: 5px;
	  margin-top: 20px;
	  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
	  display: flex;
	  align-items: center;
	  /* justify-content: center; */
  }
  .certified p{
	  margin-bottom: 0px;
	  color: #5f5f5f;
  }
  .certified p a{
	  color: #5f5f5f;
  }
  
  .welcome{
	  /* background-image: url('../src/images/welcome.png'); */
	  background-size: cover;
	  background-repeat: no-repeat;
  }
  
  .navbar-light .navbar-nav .active.nav-link {
    background-color: transparent !important;
    color: #01524b !important;
}
::placeholder { /* Recent browsers */
  text-transform: capitalize;
}
::-webkit-input-placeholder { /* WebKit browsers */
  text-transform: capitalize;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  text-transform: capitalize;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
  text-transform: capitalize;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
  text-transform: capitalize;
}
   /* Admin sidebar start */
 .MuiPaper-rounded {
	border-radius: 10px !important;
  }
  .sidebar .MuiPaper-root{
	background-color: #f8f8f8;
	/* background-image: url('../src/images/sidebar-bg.png'); */
	background-repeat: no-repeat;
	background-position: right 0px bottom 0px;
  }
  .sidebar .MuiCardContent-root{
  padding: 0 !important;
  
  }
  .sidebar .MuiList-padding{
	padding: 0;
  }
  .sidebar .MuiListItem-divider{
	background-color: #01524B;
	background-image: url('../src/images/sidebar-title-bg.png');
	background-repeat: no-repeat;
	background-position: right 0px top 0px;
	padding: 5px 16px;
   color: #fff;
  }
  .sidebar .MuiListItem-divider .MuiTypography-body1 {
	font-size: 1.3rem;
  }
  .sidebar .MuiListItem-button a{
  color: #424242;
  }
  .sidebar .MuiListItem-button a:hover{
	text-decoration: none;
	}
  
  .MuiTableCell-root{
	  padding: 8px 16px !important;
  }
  /*!
   * (c) Mimin Admin Page v1.0
   */
  
   /*  */
 .bellows-cal-hed{
	float: right;
    font-size: 26px;
    font-family: 'Roboto',"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 600;
    line-height: 51px;
    margin-bottom: 0;

 }
.owl-carousel .owl-dots.disabled {
    display: block !important;
}
.certified img{
	max-width: 30px;
}
 .com-field label::after{
    content: '*';
    color: red;
    margin-left: 5px;
    font-weight: 600;
}
.MuiCollapse-entered {
    margin-left: 15px !important;
}
.access-logo img {
	width : 100%;
	margin-bottom: 5px;
}
.access-logo {
  text-align: center !important;
  margin-bottom: 15px !important;
}
.access-logo a{
  font-size: 14px;
  color: black;
  cursor: pointer;
}
.access-logo a:hover{
  color : #009C9F;
}
.material-icons {
    font-size: 20px !important;
    margin-right: 6px;
	}
.change-pass img{
	width : 100%;
}
/* .admin-signin {
	margin-top: 20px !important;
} */
.update-profile {
	margin-top: 20px;
    width: 100%;
	text-align: right;
	padding-bottom: 35px;
}
button{
	min-width: 150px !important;
    padding: 2px 15px !important;

}
.inner-top-right-btn{
	clear: both;
	float: right;

}
.inner-top-right-btn button{

    border-radius: 20px;
    background: rgb(1, 82, 75);
    color: #fff;
    min-width: 120px;
    padding: 3px 5px;
}
.inner-top-right-btn button:hover{

    border-radius: 20px;
    background: rgb(1, 82, 75);
    color: #fff;
    min-width: 120px;
    padding: 3px 5px;
}
.MuiFormHelperText-root.Mui-error {
    color: #f44336;
    font-size: 11px;
    margin-top: 0px;
    line-height: 15px;
	font-weight: 400;
	text-transform: capitalize;
}
.HeaderContainer{
  padding:0px !important ;
}
.HeaderContainer .nav-link {
  padding: .5rem 0rem 1rem 1.5rem !important;
}
.MuiFormControl-marginNormal {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}
table, tr {
	border: 1px solid #e0e0e0;
}
table .material-icons
{
  cursor: pointer;
}
table tr td, table th{
	border: none !important;
}
table tr th{
	font-weight: 600 !important;
}
table .MuiTableCell-root{
	font-size: 14px !important;
	padding: 2px 16px !important;
}
.table-striped tbody tr:nth-of-type(even) {
    background-color:#f1f3f6 !important;
}
.support-border-right{
	border-right: 1px solid #ccc !important;
}
#root{
	min-height: 92%;
	padding-bottom: 50px;
	position: relative;

}
.main-footer{
	height: 50px;
  margin-top: -50px;
  position: absolute;
  bottom: -4px;
  right:0;
  left: 0;
}
.modal-title {
    margin-bottom: 0;
    font-family: 'Roboto',"Helvetica Neue",Helvetica,Arial,sans-serif;
	font-size: 18px;
}
.modal-header{
	padding: 5px 15px;
}
.modal-header .close {
    padding: 0px !important;
    margin: 0;
    text-align: right;
}
.close:hover{
	color: #fff;
}
.modal-content button{
	width: auto !important;
	min-width: auto !important;
}
.modal-footer button{
	min-width: 150px !important;
    padding: 2px 15px !important;
}
.rct-text button{
	padding: 0 !important;
}
.modal-body {
    padding: 15px 15px 0 15px !important;
}
.modal-footer {
    border-top: 1px solid #efefef !important;
}

.navbar-light .navbar-toggler {
    color: #404E67;
    text-align: right;
    border: none;
    width: auto;
}
.navbar-light button {
    width: auto;
    padding: 0 !important;
    min-width: auto !important;
}
@media (max-width: 500px){
.bellows-cal-hed {
  width: 100%;
  text-align: center;
}

}
@media (max-width: 992px){
	.sidebar {
		margin-bottom: 15px;
  }
  .loadercenter {
    position: fixed;
      left: 45% !important;
      top: 30% !important;
      z-index: 99999999;
  }
  div.loader {
    font-size: 40px !important;
    text-align: center;
  }
}


  .owl-dots button {
    min-width:auto !important;
  }
  .container{
    width : 96% !important;
    max-width: 1920px;
  }
  .form-pricing button {
    min-width: 150px !important;
    padding: 2px 15px !important;
  }


.loadercenter {
  position: fixed;
    left: 48%;
    top: 35%;
    z-index: 99999999;
}
div.loader {
  font-size: 70px;
  text-align: center;
}

.loaded {
  font-size: 1.2em;
  font-weight: bold;
}

.loader-parent {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #060606ba ;
  z-index: 9999;
  bottom: 0px;
}

.fa-spinner:before {
  content: "\f110";
  color: #ffffff;
}

.guest-login select {
  text-transform: none;
  border-top: none;
  border-right: none;
  border-left: none;
  width: 100%;
  margin-top: 20px;
}

.guest-login select option{
  margin: 5px 0px !important;
}

.modal{
  display: block !important;
}
.modal-dialog{
    overflow-y: initial !important;
    margin: 70px auto 30px !important;
}
.modal-body{
max-height:700px;
overflow-y: auto;
}
@media (min-height: 500px) {
  .modal-body {max-height: 450px; }
}

@media (min-height: 767px) {
  .modal-body {max-height: 600px; }
}
@media (min-height: 800px) {
  .modal-body { max-height: 600px; }
 
}

.clacfor .FormulaDropdown{
  margin-top : 0px !important
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #01524b !important;
}
.guest-login select{
  font-size:14px !important ;
}
.calculatorPopup{
  width:95%;
  max-width: 600px;
  margin: 0px auto;
}
.userPopup{
  width:95%;
  max-width: 900px;
  margin: 0px auto;
}

.MuiInputLabel-shrink{
  font-weight: 700 !important;
}
.user-login .forgot-pass a:hover{
  color: #009C9F  !important;
}

.sidebar .MuiTypography-body1 {
  text-transform: capitalize;
}
.modal-open .modal{
  overflow-y: hidden !important;   
}
.popup-heading{
  font-weight: 600;
}
.modal-body tr,.modal-body table{
  border: none;
}

/* .fixed-head-footer .row:first-child{
  background: #efefef;
}
.fixed-head-footer .clacfor{
height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
} */
.railcalradio .MuiFormGroup-row{
  flex-direction:row !important;
}
.railcalradio .MuiRadio-colorPrimary.Mui-checked{
color: #003833 !important;
}
.railcalradio .MuiFormControlLabel-root
{
  width: 45% !important;
}

.rail-dropdown{
  width: 140px;
}
#PrintDiv .rail-dropdown,.Pricingform  .rail-dropdown{
  width: 100%;
}
.krseries-dropdown{
  width: 140px;
}
#PrintDiv .krseries-dropdown{
  width: 100%;
}

.swal-text{
color: #000;
}
.swal-button{
  min-width: 100px !important;
  border-radius: 50px !important;
  padding: 5px 25px !important;
  float: right !important;
  border: none;
  margin-left: 10px;
  font-weight: 500 !important;
  text-transform: uppercase !important;
  font-size: 14px !important;
}
.swal-button--catch,.swal-button--confirm{
  background: #01524b !important;
  color: #fff !important;
  
}
.swal-button--cancel{
  background: transparent !important;
  color: #01524b !important;
  border: 1px solid #01524b !important;
} 
.swal-footer{
  text-align: center;
}
.swal-button-container{
  margin: 0px;

}

.comingsoon {
  border-radius: 15px 15px 15px 15px;
  padding: 110px 60px 60px 60px;
}
table img{
  width: 80%;
}
table .W10{
  width: 10%;
}
table .W20{
  width: 20%;
}
table .W30{
  width: 30%;
}
table .W40{
  width: 40%;
}
table .W50{
  width: 50%;
}
.manufacturer-model{
  width: 50%;
  max-width: 900px;
  margin: 0px auto;
}

.pb-10{
  padding-bottom: 10px;
}
.pb-20{
  padding-bottom: 20px;
}
.pb-30{
  padding-bottom: 30px;
}
.pb-40{
  padding-bottom: 40px;
}
.pt-10{
  padding-top: 10px;
}
.pt-20{
  padding-top: 20px;
}
.pt-30{
  padding-top: 30px;
}
.pt-40{
  padding-top: 40px;
}
.loginusername{
  color: #003833;
  font-weight: 600;
}
.LeftNavigation .menunotification{
  background-color: #01524b;
  color: #fff;
  position: relative;
  top: -13px;
  right: 14px;
  padding: 0.2em 0.6em 0.2em 0.6em;
  border-radius: 10rem;
}

.LeftNavigation .MuiListItemText-multiline{
  display: flex !important;
}
@media (min-width: 992px){
.logoutModel{
  width: 40%;
  margin: 0px auto;
}
}
.logoutModel .modal-footer button
{
  min-width: 100px !important;
}

@media (max-width: 991.99px) and (min-width:768px){
  .logoutModel{
    width: 60%;
    margin: 0px auto;
  }
}
@media (max-width : 991px){
  .nav-pipe
{
 display: none; 
}
.HeaderContainer .nav-link {
  padding: .5rem 0rem 1rem 0rem !important;
}
}
@media (max-width: 767.99px) and (min-width:576px){
  .logoutModel{
    width: 70%;
    margin: 0px auto;
  }
}
@media (max-width: 575.99px) {
  .logoutModel{
    width: 80%;
    margin: 0px auto;
  }
}
.logoutModel .btn-danger{
  border-radius: 50px !important;
}
.logoutModel .btn-primary:hover{
  background : #01524b !important
}
.LeftNavigation a{
  color:#000000de !important
}
.formulaModel{
  width: 99% !important;
  margin: 0px auto !important;
}
.mt-10{
margin-top: 10% !important;
}

@media (max-width: 576px) {
.width-500{
  width: 500px !important;
  overflow-x: auto;
}
}

@media (max-width: 1020px) {
  .width-990{
    width: 990px !important;
    overflow-x: auto;
  }
  }

  @media (max-width: 768px) {
  .manufacturer-model{
    width: 80%;
    max-width: 900px;
    margin: 0px auto;
  }
  
}
@media (max-width: 423px) { 
  .manufacturer-model .save{
    margin-bottom: 5px !important;
  }
  
}
.addbutton
{
  margin: 5px 0px !important;
}
@media(max-height:400px)
{
  .modal-body{
    height: 205px !important;
  }
}
.border-bottom{
  border-bottom:1px solid #e0e0e0
}
.rail-cal-form-control .MuiInputBase-root .MuiSelect-root{
  padding-left: 2px;
}
.rail-cal-form-control .MuiInputBase-root .MuiSelect-root .MuiInputBase-input{
  padding-left: 2px;
}
.save:hover{
  cursor: pointer;;
}
.cancle:hover{
  cursor: pointer;;
}
.close:hover{
  cursor: pointer;;
}
.TopButton{
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: red;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
}

.TopButton:hover {
  background-color: #555;
}
@media (max-width: 1280px) {
  .listingTable{
    width: 1000px !important;
    overflow-x: auto !important;
  }
}
@media(max-width:768px){
  .sm-center{
    text-align: center !important;
  }
  .sm-center .submit-btn{
    float: none !important;
    display: block !important;
  }
  .sm-center .part-number-form{
    width: 100% !important;
  }
}
.sm-center .sm-center{
  display: inline !important;
}

.navbar-light .collapsed .navbar-toggler-icon {
   background-image: url(./images/bars.png) !important; 
}

.navbar-light  .navbar-toggler-icon {
  background-image:  url(./images/close.png) !important;
}
.navbar-light .navbar-toggler-icon:focus{
  box-shadow: none !important;
  border: none !important;
  outline : none !important;
}
.logoutModel .print-btn{		  
	background: #01524b !important;
    color: #fff !important;
    border-radius: 50px !important;
    padding: 5px 25px !important;
    float: right !important;
    border: none;
    margin-left: 20px;
    margin-bottom: 5x;
}
@media (max-width:576px){
.footer-copyright span{
 display: block;
}
}

.swal-button--close{
  padding: 0px !important;
    position: absolute;
    top: -136px;
    min-width: 30px !important;
    right: -136px;
    background: #01524b !important;
    height: 30px;
}
.btn-search,.btn-search:hover{
  min-width: auto !important;
  background-color:#01524b !important;
  border-color: #01524b !important;
}
#search{
 border:  1px solid #CCD6E6 !important;
}
.searchbar button{
min-width: auto !important;
}