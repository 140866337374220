.modal-header{
    background-color: #01524b;
    color: #fff;
}
.close{
    color:#fff;
}
.save{
    background: #01524b !important;
    color: #fff !important;
    border-radius: 50px !important;
    padding: 5px 25px !important;
    border: 1px solid #01524b !important;
}
.cancle{
    background: transparent !important;
    color: #01524b !important;
    border: 1px solid #01524b !important;
    border-radius: 50px !important;
    padding: 5px 25px !important;
}

/*pagination*/
.custom-pagination{

  margin-top: 20px;
  text-align: center;
  justify-content: center;
  display: flex;
}
.page-item.active .page-link{
  background-color: #01524b !important;
  color: #fff !important;
}
.page-link{
  color:#01524b!important;
}
 .modal-dialog{
  max-width: 100%;
  margin: 30px auto;
}
.ManufacturerDropDown.MuiSelect-root .MuiSelect-select .MuiSelect-selectMenu .MuiInputBase-input .MuiInput-input .MuiInputBase-inputSelect {
  width:250px !important;
  margin-top: 10px !important;
}
.submit{
  clear: both;
    float: left;
    border-radius: 20px;
    background: rgb(1, 82, 75);
    color: rgb(255, 255, 255);
    font-weight: normal;
}

/* .FormulaDropdown{
  margin-top: 15px !important;
} */
.Manufacturerform
{
  width:170px !important;
}
.FormulaDropdown #demo-controlled-open-select{
  border-top:white !important;
  border-left:white !important;
  border-right:white !important;
}

@media (min-width: 992px) {
  /* .col-sm-0half, .col-sm-1half {
      float: left;
  } */

}
  .col-sm-0half {
      width: 3.16666667% !important;
  }
  .col-sm-1half {
      width: 5.83333333% !important;
  }
  .col-sm-1half
  {
    width: 11.83333333% !important;
    min-width: 10.0% !important;
  }
.p-l-15{
  padding-left: 15px;
}
.clacfor .MuiInputBase-input
{
padding:0px !important;
}

.MuiCardContent-root {
  padding: 15px !important;
}
.manageFormula table .MuiTableCell-root  {
  text-align: center !important;
}
.selecterrormsg{
  color: red;
    font-size: 11px;
    margin-top: 0px;
    line-height: 15px;
    font-weight: 400;
    text-transform: capitalize;
}