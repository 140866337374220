.pricinghistory .MuiFormControl-root{
	display: block;
}
.pricinghistory .MuiInputLabel-formControl{
	left: unset;
}

.form-pricing .MuiIcon-root,.form-pricing abbr{
	cursor: pointer;
}

.main-sec{
     border: 1px dashed #ccc;
     padding: 10px 0 20px;
     border-radius: 10px;
     margin-bottom: 15px;
}

.filepond--drop-label {
	color: #4c4e53;
}

.filepond--label-action {
	text-decoration-color: #babdc0;
}

.filepond--panel-root {
	border-radius: 2em;
	background-color: #edf0f4;
	height: 1em;
}

.filepond--item-panel {
	background-color: #595e68;
}

.filepond--drip-blob {
	background-color: #7f8a9a;
}
