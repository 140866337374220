.modal-header{
    background-color: #01524b;
    color: #fff;
}
.close{
    color:#fff;
}
.save{
    background: #01524b !important;
    color: #fff !important;
    border-radius: 50px !important;
    padding: 5px 25px !important;
    border: 1px solid #01524b !important;
}
.cancle{
    background: transparent !important;
    color: #01524b !important;
    border: 1px solid #01524b !important;
    border-radius: 50px !important;
    padding: 5px 25px !important;
}
.swal-title {
  margin: 0px;
  font-size: 16px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.21);
  margin-bottom: 28px;
}
.swal2-close {
min-width: 50px !important;
}
.swal2-styled.swal2-confirm{
  background: #01524b !important;
  padding: 5px 15px !important;
  font-size: 14px !important;
  border-radius: 50px !important;
  border: 1px solid #01524b !important;
  min-width: 100px !important;
}
.swal2-styled.swal2-cancel{
  padding: 5px 15px !important;
  font-size: 14px !important;
  border-radius: 50px !important;
 min-width: 100px !important;
}

/*pagination*/
.custom-pagination{

  margin-top: 20px;
  text-align: center;
  justify-content: center;
  display: flex;
}
.page-item.active .page-link{
  background-color: #01524b !important;
  color: #fff !important;
}
.page-link{
  color:#01524b!important;
}
 .modal-dialog {
  max-width: 100%;
  margin: 30px auto;
}
.ManufacturerDropDown.MuiSelect-root .MuiSelect-select .MuiSelect-selectMenu .MuiInputBase-input .MuiInput-input .MuiInputBase-inputSelect {
  width:250px !important;
  margin-top: 10px !important;
}

.FormulaDropdown{
  margin-top: 15px !important;
}
.Manufacturerform
{
  width:170px !important;
}
.Manufacturerform .MuiFormControl-marginNormal
{
  margin-top:0px !important;
}

.FormulaDropdown #demo-simple-select
{
border-top: white !important;
border-left: white !important;
border-right: white !important;
}