.partnumbercodinimg{
    width: 100%;
}
.parameter{
    margin:0 0 30px 0;
    position: relative;
}
.calculator-headings{
    text-transform: uppercase;
    padding: 5px;
    font-size: 16px;
    font-weight: 600;
    margin: 0px;
    background: #fff;
    position: absolute;
    top: -17px;

}
.dotted-border{
    border: 1px dashed #ccc;
    padding: 10px;
    border-radius: 10px;
    margin-top: 10px;
}
.calc-image {
    text-align: center
}
.dotted-border .img-calculator{
    width: 80%;
}
.parameter-head table {
    margin-bottom: 10px;
    border: 1px solid #ccc;
}
.parameter-head table tr td{
    border: none;
    padding: 10px;
}
table{
    margin-bottom: 10px;
}
table, table tr td, table th{
    border: 1px solid #ccc; 
    padding: 5px !important;  
}
.responsiveTable{
    width: 100%;
    overflow-x: auto;
}
.grayheader{
    background: #eaeaea;
    font-weight: 600 !important;
}
.part-number-form .MuiInput-formControl {
border-bottom: 1px solid gray;
}
.part-number-form{
    float: left;
    width: 100%;
}
.part-number-form label span{
color: red;
}
.getdetails-btn{
	background: #01524b !important;
    color: #fff !important;
    border-radius: 50px !important;
    padding: 5px 25px !important;
    border: none;
}
.add-more-btn{
	background: #01524b !important;
    color: #fff !important;
    border-radius: 50px !important;
    padding: 5px 25px !important;
    float: right !important;
    border: none;
					  
}				 
#PrintDiv .print-btn{		  
	background: #01524b !important;
    color: #fff !important;
    border-radius: 50px !important;
    padding: 5px 25px !important;
    float: right !important;
    border: none;
    margin-left: 20px;
    margin-bottom: 10px;
}

.parameter-head .part-no{
    text-align: right;
    font-weight: 600;
}
.parameter-head .part-no span{
    font-weight: 400;
}
.part-number{
    background: #eaeaea !important;
    color: #000 !important;
    border-radius: 50px !important;
    padding: 5px 25px !important;
    margin: 0 10px;
    border: 1px solid #ccc;
}
.pricing-details{
    margin: 15px 0;
}
.pricing-details h4{
font-size: 16px;
font-weight: 600;
color: #01524b;
}
.pricing-details ul {
    list-style-type: none;
    padding-left: 0;
    color: #666;
    margin-left: 20px;
}
.pricing-details li::before {
    position: relative;
    margin-bottom: 20px;
    font-family: FontAwesome;
    content: "\f101";
    color: #01524b;
    margin-left: -20px;
    padding-right: 10px;
}

 
.main-calc-btn{
    background: #01524b !important;
    color: #fff !important;
    border-radius: 8px !important;
    padding: 10px 25px !important;
    border: none;
    margin-right: 10px;
    margin-right: 10px !important;
}
.main-btn button a{
    color: #fff;
}
.main-btn button a:hover{
    text-decoration: none;
}
.block2p p{
    margin-bottom: 0;
}
.product-line .MuiFormControl-marginNormal {
    margin-top: 0px;
    margin-bottom: 10px;
    float:right;
    max-width: 200px;
}

.product-line .MuiFormControl-marginNormal input {
    overflow: hidden;
    background: transparent;
    border: none;
    display: block;
}
.part-number-coding{
    min-height: 600px;
}
.row-fields .col-lg-2,.row-fields .col-lg-1{
    padding-right: 5px !important;
    padding-left: 5px !important;
}
@media (min-width:992px) and (max-width:8000px){
.row-fields .leftelement{
    padding-left: 15px !important;
}
}
@media(max-width:991px){
    .row-fields .MuiFormControl-marginNormal{
        max-width: 92%;
    }
    .row-fields .leftelement{
        padding-left: 5px !important;
    }
.part-number-coding{
    min-height: 0px;
    
}
.cal-sidebar{
    margin-bottom:20px;
}
.main-btn{
    text-align: center;
}
}
.pt-100{
    padding-top:100PX ;
}
@media(width:768px){
.partnumbercodinimg {
    width: 100%;
    margin-top: 30px;
}
}
@media(max-width:868px) and (min-width:769px){
    .partnumbercodinimg {
        width: 100%;
        margin-top: 30px;
    }
    }
@media(max-width:1024px){
    .main-btn button {
        padding: 5px 10px !important;
        margin-bottom: 10px;
    }
}
/* .calculator-rightside{
    margin-bottom: 40px;
} */
.calc-tab{
    /* background: #c7d9d8 !important; */
    /* background: #598f8a !important;
    margin-right: 10px !important;
    color: rgba(255,255,255,.5) !important;
    border-radius: 8px !important;
    padding: 10px 25px !important;
    border: none;
    margin-right: 10px; */

    background: #fff !important;
    /* background: #598f8a !important; */
    margin-right: 10px !important;
    color: black !important;
    border-radius: 8px !important;
    padding: 10px 25px !important;
    border: 1px dashed #ccc !important;
    margin-right: 10px;
  }
  .part-number-form .part-number{
    width: 50% !important;
  }
  .part-number-form .part-number .MuiFormControl-fullWidth
  {
    width: 100% !important;
  }

  .part-number-form .part-number .MuiFormControl-marginNormal
  {
      margin: 0px !important;
  }
  .part-number-form .part-number
  {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      margin-top: 0px !important;
      margin-left: 5px !important;
  }
  .part-number-form .MuiInput-underline:before,.part-number-form .MuiInput-underline::after
  {
      display: none;
  }
  .MuiInputBase-root.Mui-disabled
  {
    background-color: lightgray;
  }
  #PrintDiv table .MuiTableCell-root
  {
      padding : 2px 8px !important;
  }
.Custom-Label{
    left:auto !important;
}
.Partnumberform .MuiExpansionPanelDetails-root{
    display:block !important;
    padding:0px !important;
}
.MuiPaper-elevation1{
    box-shadow:none !important;
}
.MuiCollapse-entered{
    margin-left: 0px !important;
}
.MuiExpansionPanelSummary-root
{
    padding: 0px 0px 0px !important;
}
.MuiExpansionPanelSummary-root.Mui-focused
{
    background-color: #fff !important;
}
.MuiExpansionPanelSummary-content.Mui-expanded{
    margin:12px 0 !important;
}
.MuiExpansionPanelSummary-content{
    margin: 12px 0 !important;
}
.MuiInputBase-root.Mui-disabled #centerLine{
    color: rgba(0, 0, 0, 0) !important;
}
.centerlinedistance .MuiFormControl-marginNormal
{
    margin-top:8px !important;
}
.railcalradio .Mui-disabled{
    background: #f9f3f3 !important;
    color: #0d0e10 !important;
}

#PrintDiv  .Mui-disabled{
    padding-left: 2px;
}
#PrintDiv  .MuiCheckbox-root{
    padding-left: 2px;
}
.bellowParamDiv table tr td{
    width: 100px;
}

.bellowParamDiv table tr th{
    width: 100px;
}

@media(max-width:576px){
    .sm-center .part-number{
    width: 100% !important;
    }
    .calcOutput .product-line .MuiFormControl-marginNormal {
        float:none;
    }
    .calcOutput .product-line{
        text-align: center;
        margin-bottom: 10px;
    }
    .calcOutput .product-line .MuiSelect-selectMenu{
        text-align: left;
    }
}
.table td,.table th{
    border:1px solid #cec6c6 !important;
    vertical-align: middle;
}
.table .odd-row{
background-color: #f1f3f6 !important;
}

.row-fields .submit-btn {
font-size: 15px !important;
margin: 0 auto;
}
.row-fields button{
    margin-top: 15px !important;
    max-width: 100px !important;
    min-width: 100px !important;
    padding: 5px 8px !important;
}
.row-fields button .MuiButton-label{
    font-size: 12px !important;
}

.tablerow {
    padding-left: 5px !important;
    padding-right: 5px !important;
}
img {
    -webkit-print-color-adjust: exact;
}
