.greenButton{
    background: #01524b !important;
    color: #fff !important;
    border: 1px solid #01524b !important;
  }
  .whiteButton{
    color: #01524b !important;
    background: #fff !important;
    border: 1px solid #01524b !important;
  }
  .footer-max-width{
    margin: 0px !important;
    margin-top: 2% !important;
    max-width: 100% !important;
  }
  .signin-heading
{
  padding: 100px 0 30px
}
  @media (max-width: 500px){
  .signin-heading
  {
    padding: 150px 0 30px
  }
}

@media (max-width: 451px) and (min-width :319px){
  .btn-toggle-center {
    text-align: center !important;
  }
  .btn-toggle-login{
    border-radius: 20px 0px 0px 20px !important;
    max-width: 110px !important;
    min-width: 110px !important;
  }
  .btn-toggle-register{
    border-radius: 0px 20px 20px 0px !important;
    max-width: 110px !important;
    min-width: 110px !important;
  }

}
@media (min-width: 452px){
  .btn-toggle-login{
    border-radius: 20px 0px 0px 20px !important;
  }
  .btn-toggle-register{
    border-radius: 0px 20px 20px 0px !important;
  }
}


@media (min-width: 0) and (max-width:1279px) {

}
@media(max-width: 1440px) and (min-width:1024px) {

}
@media(max-width: 1440px) {

}
@media(max-width: 1199px) {

}
@media(max-width: 1167px) {
}

@media (min-width: 0px) and (max-width:767px) {

}
@media(max-width: 480px) {

}
@media(min-width: 1366px) {

}
@media (max-width: 768px) {

}
@media(min-width: 1200px) and (max-width:1500px) {
}
@media(min-width: 992px) and (max-width:1199px) {

}
@media(min-width: 991px) and (max-width:1100px) {
}
@media(min-width: 768px) and (max-width:991px) {
}
@media(max-width: 991px) {
}
@media(min-width: 576px) and (max-width:767px) {
}
@media(min-width: 480px) and (max-width:575px) {
}
@media(min-width: 320px) and (max-width:479px) {

}

@media(max-width: 991px) {

}