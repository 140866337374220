.login-container .form-simple input[type="text"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.login-container .form-simple input[type="mail"] {
 margin-bottom: -1px;
 border-top-left-radius: 0;
 border-top-right-radius: 0;
 border-bottom-right-radius: 0;
 border-bottom-left-radius: 0;
}

.login-container .form-simple input[type="password"] {
 margin-bottom: 10px;
 border-top-left-radius: 0;
 border-top-right-radius: 0;
}

.login-container .help-block ul {
 margin: 0 !important;
 padding: 0 !important;
}

.login-container .help-block ul li {
 list-style: none;
}

.user-login {
    padding-bottom: 0 !important;
    padding: 30px 60px !important;
}
.user-login .forgot-pass{
    margin-bottom: 0 !important;
    margin-top: 20px;
    padding-bottom: 35px;
}

.user-login .forgot-pass a{
    color: #818181 !important;
    font-size: 14px;
}
.user-login p{
    margin-bottom: 0 !important;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    clear: both;
}
.user-login .MuiButton-text {
    padding: 2px 15px;
    margin-bottom: 20px;
}
.user-login .submit-btn{
    min-width: 200px;
}
.regi-btn{
    margin-top: 20px;
}
.regi-btn button{
    margin-bottom: 0px !important;
}
.user-login .MuiFormControl-marginNormal {
    margin-top: 5px;
    margin-bottom: 5px;
}
.MuiButton-label,.print-btn, .modal-footer button {
    font-weight: 500 !important;
    text-transform: uppercase !important;
    font-size: 14px !important;
      
}
.modal-footer button{
    padding: 5px 15px !important;
}

.guest-login .MuiCardContent-root{
    padding: 108px 60px 30px !important;
}
.for-btn{
    margin-top: 30px;
}
.forgot-pass-main{
    margin-top: 48px;
}
@media (max-width: 768px) and (min-width:455) {
    .guest-login .MuiCardContent-root{
        padding: 30px 60px 30px !important;
    }
}
@media (max-width: 456px) {
    .guest-login .MuiCardContent-root{
        padding: 30px 25px 30px !important;
    }
    .user-login{
        padding: 30px 20px !important;
    }
    
}
@media(max-width:374px)
{
    .forgot-pass button{
        margin-top:10px !important;
    }
    .btn-toggle-center{
        text-align: center !important;
    }
}