.modal-header{
    background-color: #01524b;
    color: #fff;
}
.close{
    color:#fff;
}
.save{
    background: #01524b !important;
    color: #fff !important;
    border-radius: 50px !important;
    padding: 5px 25px !important;
    border: 1px solid #01524b !important;
}
.cancle{
    background: transparent !important;
    color: #01524b !important;
    border: 1px solid #01524b !important;
    border-radius: 50px !important;
    padding: 5px 25px !important;
}

/*pagination*/
.custom-pagination{

  margin-top: 20px;
  text-align: center;
  justify-content: center;
  display: flex;
}
.page-item.active .page-link{
  background-color: #01524b !important;
  color: #fff !important;
}
.page-link{
  color:#01524b!important;
}
.guestexceed{
  color:red !important;
}