.footer{
    padding: 10px 0 0;
    background: #01524b;
    color:#fff;
    padding-top:20px;
    text-align: center;
}
.footer a{
    color:#fff;
}
.footer a:hover{
text-decoration: none;
}
.footer-copyright{
    background: #003833;
    color:#fff;
    padding-top: 15px;
    text-align: center;
}
@media(max-width:991px){
    .footer{ text-align:left;}
}
@media (max-width: 767px){
    
    .footer-certified .certified-col
    {
        text-align: left;
        margin: 5px 0px;
    }
    .support-border-right {
        border-right: none !important;
    }
  }